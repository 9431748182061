import {Component, OnInit} from '@angular/core';
import {LipoDetailComponent} from "../../../shared/components/lipo-detail/lipo-detail.component";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {ActivatedRoute, Data, Router} from "@angular/router";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {SystemDataService} from "../../../system/services/system-data.service";
import {TranslateService} from "@ngx-translate/core";
import {LipoRoutesDataModel} from "../../../shared/models/lipo-routes-data.model";
import {TenantUserMappingDetailModel} from "../models/tenant-user-mapping-detail.model";
import {TenantUserMappingServiceMapper} from "../../mappers/tenant-user-mapping-service.mapper";
import {LipoFormModel} from "../../../shared/models/lipo-form.model";
import {TenantUserMappingDetailMapper} from "../../mappers/tenant-user-mapping-detail.mapper";
import {getTenantUserMappingDetailFormFields} from "./tenant-user-mapping-detail.form-fields";
import {CustomerDataService} from "../../../customer/services/customer-data.service";
import {UserServiceModel} from "../../../user/services/models/user-service.model";
import {TenantServiceModel} from "../../services/models/tenant-service.model";
import {SystemServiceModel} from "../../../system/services/models/system-service.model";
import {LipoFormMapper} from "../../../shared/mappers/lipo-form.mapper";
import {TenantUserMappingDataService} from "../../services/tenant-user-mapping-data.service";
import {ProgressService} from "../../../shared/services/progress.service";
import {
  addAppNameControl,
  removeAppNameSettingsFromForm
} from "../tenant-app-setting-detail/tenant-app-settings-detail.form-fields";
import {ConfigurationService} from "../../../shared/services/configuration.service";
import {AppLicenseSettingsConfiguration} from "../../../shared/services/models/configuration.model";
import {TenantAppSettingsDataService} from "../../services/tenant-app-settings-data.service";
import {TenantAppSettingsServiceModel} from "../../services/models/tenant-app-settings-service.model";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {MatDialog} from "@angular/material/dialog";
import {
  TenantAppSettingSelectionDialogComponent
} from "../tenant-app-setting-selection-dialog/tenant-app-setting-selection-dialog.component";
import {TenantAppSettingsDetailModel} from "../models/tenant-app-settings-detail.model";

@Component({
  selector: 'du-tenant-user-mapping-detail',
  standalone: true,
  imports: [
    LipoDetailComponent
  ],
  templateUrl: './tenant-user-mapping-detail.component.html',
  styleUrl: './tenant-user-mapping-detail.component.scss'
})
export class TenantUserMappingDetailComponent implements OnInit {
  protected readonly LipoRouteEnum = LipoRouteEnum;

  detailModel?: LipoDetailModel;
  availableTenantAppSettings: TenantAppSettingsServiceModel[] = []
  selectedTenantAppSettings: TenantAppSettingsDetailModel[] = []
  systemId: number | null = null;
  tenantId: number | null = null;
  userMappingId: number | null = null;
  oldTenant: TenantServiceModel | null = null;
  tenants: TenantServiceModel[] = [];
  users: UserServiceModel[] = [];
  headerButtons: LipoButton[] = []

  readonly appLicenseSettings: AppLicenseSettingsConfiguration

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _snackBar: SnackbarService,
    private _tenantUserMappingService: TenantUserMappingDataService,
    private _tenantAppSettingService: TenantAppSettingsDataService,
    private _systemService: SystemDataService,
    private _customerService: CustomerDataService,
    private _translationService: TranslateService,
    private _progressService: ProgressService,
    private dialog: MatDialog,
    _config: ConfigurationService
  ) {
    this.appLicenseSettings = _config.configuration.appLicenseSettings;
    _progressService.startLoading()
  }

  ngOnInit(): void {
    this._activatedRoute.data.subscribe({
      next: data => {
        this.handleRouteData(data);
      }
    });
  }

  private handleRouteData(data: Data): void {
    const routesDataModel = data as LipoRoutesDataModel;
    this.handleParams();
    this.handleTenantUserMappingData(routesDataModel);
  }

  private handleParams(): void {
    this._activatedRoute.paramMap.subscribe(params => {
      this.systemId = this.idToNumber(params.get('systemId'));
      this.tenantId = this.idToNumber(params.get('tenantId'));
      this.userMappingId = this.idToNumber(params.get('userMappingId'));
    });
  }

  private idToNumber(idString: string | null): number | null {
    if (idString === null) {
      return null;
    }
    const num = +idString;
    return isNaN(num) ? null : num;
  }

  private handleTenantUserMappingData(routesDataModel: LipoRoutesDataModel): void {
    if (this.systemId !== null) {
      this._systemService.getSystem(this.systemId).subscribe({
        next: system => this.processSystemData(system, routesDataModel)
      });
    }
  }

  private processSystemData(system: SystemServiceModel, routesDataModel: LipoRoutesDataModel): void {
    this._customerService.getCustomer(system.customer).subscribe({
      next: customer => {
        this.tenants = system.tenants;
        this.users = customer.users;

        this.oldTenant = system.tenants.find(tenant => tenant.id === this.tenantId) ?? null;
        routesDataModel.isCreateItem ? this.createEmptyTenantUserMapping() : this.loadTenantUserMapping();
      }
    });
  }

  private loadTenantUserMapping(): void {
    if (this.userMappingId !== null && this.systemId !== null && this.tenantId !== null) {
      this._tenantUserMappingService.getTenantUserMapping(this.systemId, this.tenantId, this.userMappingId).subscribe({
        next: tenantUserMapping => this.setUserMapping(TenantUserMappingServiceMapper.toTenantUserMappingDetailModel(tenantUserMapping)),
        error: () => this.navigateToTenantNotFound()
      })
    }
  }

  private navigateToTenantNotFound(): void {
    this._router.navigate([LipoRouteEnum.SYSTEM, this.systemId, LipoRouteEnum.TENANT, this.tenantId])
      .then(() => this._snackBar.Warning(this._translationService.instant('snackbar.notfound')));
  }

  setUserMapping(userMapping: TenantUserMappingDetailModel): void {
    this.createDetail(userMapping);
  }

  createEmptyTenantUserMapping(): void {
    let emptyTenantModel = new TenantUserMappingDetailModel();
    emptyTenantModel.tenantId = this.tenantId;

    this.createDetail(emptyTenantModel);
  }

  createDetail(userMapping: TenantUserMappingDetailModel): void {
    getTenantUserMappingDetailFormFields(userMapping, this.users, this.tenants, this._snackBar, this._translationService).subscribe({
      next: userMappingFormGroup => {
        let lipoFormModel = new LipoFormModel([userMappingFormGroup])
        this.selectedTenantAppSettings = userMapping.appSettings ?? []
        this.detailModel = TenantUserMappingDetailMapper.toLipoDetailModel(userMapping, lipoFormModel)
        this.addAppNameSettingsToForm(this.selectedTenantAppSettings)
        this._tenantAppSettingService.getAllAppSettings(this.systemId!, this.tenantId!).subscribe({
          next: value => {
            this.availableTenantAppSettings = value;
            if (value.length > 0) {
              this.addAppNameSettingsButton()
            }
          }
        })

      },
      complete: () => this._progressService.stopLoading()
    });
  }

  onDeleteClick(): void {
    if (this.userMappingId !== null && this.systemId !== null && this.tenantId !== null) {
      this._tenantUserMappingService.deleteTenantUserMapping(this.systemId, this.tenantId, this.userMappingId).subscribe({
        next: tenant => this._router.navigate([LipoRouteEnum.SYSTEM, this.systemId, LipoRouteEnum.TENANT, tenant.id])
          .then(() => this._snackBar.Deleted())
      });
    }
  }

  onSaveClick(detailModel: LipoDetailModel): void {
    if (!detailModel.form?.formGroup || !this.systemId) return;

    let tenantUserModel = LipoFormMapper.toTenantUserMappingServiceModel(detailModel.form?.formGroup, this.userMappingId, this.selectedTenantAppSettings, this.appLicenseSettings);

    if (this.oldTenant !== null) {
      if (this.userMappingId !== null && this.userMappingId > 0) {
        this._tenantUserMappingService.updateTenantUserMapping(this.systemId, this.oldTenant.id!!, tenantUserModel).subscribe({
          next: tenantUserMapping => {
            this.createDetail(TenantUserMappingServiceMapper.toTenantUserMappingDetailModel(tenantUserMapping));
            this._snackBar.Saved();
          }
        });
      } else {
        this._tenantUserMappingService.createTenantUserMapping(this.systemId, this.oldTenant.id!!, tenantUserModel).subscribe({
          next: tenantUserMapping =>
            this._router.navigate([LipoRouteEnum.SYSTEM, this.systemId, LipoRouteEnum.TENANT, this.tenantId, LipoRouteEnum.USER_MAPPING, tenantUserMapping.id]).then(() => this._snackBar.Saved())
        });
      }
    }
  }

  addAppNameSettingsButton(): void {
    this.headerButtons = LipoButton.build(
      {
        text: "button.appSettings.select",
        icon: "checklist",
        onClick: async () => {
          this.openAppNameSelectionDialog()
        }
      }
    )
  }

  openAppNameSelectionDialog(): void {
    const dialogRef = this.dialog.open(TenantAppSettingSelectionDialogComponent, {
      data: {
        availableTenantAppSettings: this.availableTenantAppSettings,
        selectedTenantAppSettings: this.selectedTenantAppSettings
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result: TenantAppSettingsDetailModel[] | undefined) => {
        if(result) {
          let oldSelectedAppSettings = [...this.selectedTenantAppSettings ?? []];

          let addedAppSettings = this.findAddedItems(oldSelectedAppSettings, result);
          let removedAppSettings = this.findRemovedItems(oldSelectedAppSettings, result);

          this.addAppNameSettingsToForm(addedAppSettings);
          removeAppNameSettingsFromForm(removedAppSettings.map(app => app.appName), this.detailModel);

          if (addedAppSettings.length > 0 || removedAppSettings.length > 0) {
            this.detailModel?.form?.formGroup.markAsDirty()
          }

          this.selectedTenantAppSettings = result;
        }
      }
    });
  }

  findAddedItems(oldList: TenantAppSettingsDetailModel[], newList: TenantAppSettingsDetailModel[]): TenantAppSettingsDetailModel[] {
    const oldIds = new Set(oldList.map(item => item.id));
    return newList.filter(item => !oldIds.has(item.id));
  }

  findRemovedItems(oldList: TenantAppSettingsDetailModel[], newList: TenantAppSettingsDetailModel[]): TenantAppSettingsDetailModel[] {
    const newIds = new Set(newList.map(item => item.id));
    return oldList.filter(item => !newIds.has(item.id));
  }

  addAppNameSettingsToForm(appSettings: TenantAppSettingsDetailModel[] = []): void {
    for (let appSetting of appSettings) {
      addAppNameControl(appSetting.appName, this.appLicenseSettings, this.detailModel!, appSetting.attributes).then()
    }
  }

}
