import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {LicenseWizardModel} from "../models/license-wizard.model";
import {SystemServiceModel} from "../../../system/services/models/system-service.model";
import {TenantServiceModel} from "../../../tenant/services/models/tenant-service.model";
import {ProductServiceModel} from "../../../product/services/models/product-service.model";
import {SystemDataService} from "../../../system/services/system-data.service";
import {forkJoin} from "rxjs";
import {ProductDataService} from "../../../product/services/product-data.service";
import {MatStepper, MatStepperModule} from "@angular/material/stepper";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MatSelectModule} from "@angular/material/select";
import {ProductPriceServiceModel} from "../../../productPrice/services/models/product-price-service.model";
import {UpperCasePipe} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {A11yModule, CdkTrapFocus} from "@angular/cdk/a11y";
import {MatTableModule} from "@angular/material/table";
import {MatCheckbox, MatCheckboxModule} from "@angular/material/checkbox";
import {LicenseService} from "../../services/license.service";
import {LicenseAddServiceModel} from "../../services/models/app-license-view-service.model";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@Component({
  selector: 'du-wizard',
  standalone: true,
  imports: [
    MatDialogModule,
    MatStepperModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    TranslateModule,
    UpperCasePipe,
    MatIcon,
    CdkTrapFocus,
    A11yModule,
    MatTableModule,
    MatCheckboxModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './license-wizard-dialog.component.html',
  styleUrl: './license-wizard-dialog.component.scss'
})
export class LicenseWizardDialogComponent implements OnInit{
  @ViewChild('acceptCheckbox') acceptCheckbox!: MatCheckbox;

  _isSavingAppLicense: boolean = false;

  data = inject(MAT_DIALOG_DATA) as LicenseWizardModel;
  setupFormGroup!: FormGroup
  overviewFormGroup!: FormGroup

  systems: SystemServiceModel[] = []
  products: ProductServiceModel[] = []
  tenants: TenantServiceModel[] = []
  productPrices: ProductPriceServiceModel[] = []

  constructor(
    private _systemService: SystemDataService,
    private _productService: ProductDataService,
    private _appLicenseService: LicenseService,
    private _snackbar: SnackbarService,
    private _formBuilder: FormBuilder,
    private _dialogRef: MatDialogRef<LicenseWizardDialogComponent>,
    private _translate: TranslateService,
  ) {
    this._dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    forkJoin({
      systems: this._systemService.getSystems(),
      products: this._productService.getProducts(),
    }).subscribe({
      next: ({ systems, products }) => {
        this.systems = systems;
        this.products = products;
        this.setSetupFormValue();
        this.setPaymentFormValue();
      },
      error: _ => {
        this._dialogRef.close();
      }
    });
  }

  private setSetupFormValue(): void {
    this.setupFormGroup = this._formBuilder.group({
      systemCtrl: [{ value: this.data.systemId, disabled: this.data.systemId !== null }, Validators.required],
      tenantCtrl: [{ value: this.data.tenantId, disabled: this.data.systemId === null || this.data.tenantId !== null}, Validators.required],
      productCtrl: [{ value: this.data.productId, disabled: this.data.productId !== null }, Validators.required],
      priceCtrl: [{ value: this.data.productPriceId, disabled: this.data.productId === null || this.data.productPriceId !== null }, Validators.required],
    });
    this.setupFormFieldSubscription();

    this.onSystemSelected(this.data.systemId);
    this.onProductSelected(this.data.productId);
  }

  private setPaymentFormValue(): void {
    this.overviewFormGroup = this._formBuilder.group({
      acceptCtrl: [false, Validators.requiredTrue],
    })
  }

  onSystemSelected(systemId: number | null): void {
    this.tenants = this.systems.find(system => system.id === systemId)?.tenants ?? []

    if (this.tenants.length === 1) {
      this.setupFormGroup?.get('tenantCtrl')?.setValue(this.tenants[0].id)
    }
  }

  onProductSelected(productId: number | null): void {
    this.productPrices = this.products.find(product => product.id === productId)?.productPrice ?? []

    if (this.productPrices.length === 1) {
      this.setupFormGroup?.get('priceCtrl')?.setValue(this.productPrices[0].id)
    }
  }

  private setupFormFieldSubscription(): void {
    this.setupFormGroup!.get('systemCtrl')?.valueChanges.subscribe(systemValue => {
      const tenantCtrl = this.setupFormGroup!.get('tenantCtrl');
      if (systemValue) {
        tenantCtrl?.enable();
      } else {
        tenantCtrl?.disable();
      }
    });

    this.setupFormGroup!.get('productCtrl')!.valueChanges.subscribe(productValue => {
      const priceControl = this.setupFormGroup!.get('priceCtrl');
      if (productValue) {
        priceControl?.enable();
      } else {
        priceControl?.disable();
      }
    });
  }

  getSelectedTenant(): string {
    let tenantId = this.setupFormGroup.get('tenantCtrl')?.value;
    return this.tenants.find(tenant => tenant.id === tenantId)?.sapCompanyDB ?? ''
  }

  getSelectedSystem(): string {
    let systemId = this.setupFormGroup.get('systemCtrl')?.value;
    let system = this.systems.find(system => system.id === systemId);

    if (!system) {
      return ''
    }

    return `${system.name} - ${system.identity}`
  }

  getSelectedProduct(): string {
    let productId = this.setupFormGroup.get('productCtrl')?.value;
    return this.products.find(product => product.id === productId)?.name ?? '';
  }

  getSelectedProductPrice(): string {
    let priceId = this.setupFormGroup.get('priceCtrl')?.value;
    let price = this.productPrices.find(price => price.id === priceId);

    if (!price) {
      return ''
    }

    return `${price.name} - ` + this._translate.instant(`subscription.${price.type.toLowerCase()}`) + ` - ${price.price} ${price?.currency}`;
  }

  hasNextStep(stepper: MatStepper): boolean {
    return stepper.selectedIndex < stepper.steps.length - 1;
  }

  hasPreviousStep(stepper: MatStepper): boolean {
    return stepper.selectedIndex > 0;
  }

  isCurrentStepValid(stepper: MatStepper): boolean {
    const currentStep = stepper.selected;
    const stepControl = currentStep?.stepControl;
    return stepControl ? stepControl.valid : false;
  }

  onStepChange(selectedIndex: number) {
    if (selectedIndex === 1 && this.acceptCheckbox) {
      setTimeout(() => {
        this.acceptCheckbox.focus();
      });
    }
  }

  onSubmit(): void {
    if (this.setupFormGroup.valid && this.overviewFormGroup.valid) {

      this._isSavingAppLicense = true;

      let systemId = this.setupFormGroup.get('systemCtrl')?.value;
      let tenantId = this.setupFormGroup.get('tenantCtrl')?.value;
      let productId = this.setupFormGroup.get('productCtrl')?.value;
      let priceId = this.setupFormGroup.get('priceCtrl')?.value;

      let licenseServiceModel = {productId: productId, productPriceId: priceId} as LicenseAddServiceModel

      this._appLicenseService.addAppLicenseToTenant(systemId, tenantId, licenseServiceModel).subscribe({
        next: _ => {
          this._snackbar.Success(this._translate.instant('licenseWizard.success'));
          this._isSavingAppLicense = false;
          this._dialogRef.close(true);
        },
        error: _ => this._isSavingAppLicense = false
      });
    }
  }

}
