import {
  LipoFormCheckbox,
  LipoFormControlModel,
  LipoFormMail,
  LipoFormPhone,
  LipoFormSelect,
  LipoFormTextbox
} from "../../../shared/components/models/lipo-form-control.model";
import {FormControl, Validators} from "@angular/forms";
import {RegexPatterns} from "../../../utils/regex-patterns";
import {CustomerDetailModel} from "../models/customer-detail.model";
import {asyncScheduler, Observable, scheduled} from "rxjs";
import {PartnerServiceModel} from "../../../partner/services/models/partner-service.model";

export function getCustomerDetailFormFields(model: CustomerDetailModel): Observable<LipoFormControlModel[]> {
  let fields: LipoFormControlModel[] = [
    new LipoFormTextbox({
        value: new FormControl(model.name, [Validators.required]),
        key: 'name',
        label: 'name',
        order: 1,
      }
    ),
    new LipoFormPhone({
        value: new FormControl(model.phone, [Validators.pattern(RegexPatterns.Phone)]),
        key: 'phone',
        label: 'phone',
        hint: 'hint.phone',
        order: 2,
      }
    ),
    new LipoFormMail({
      value: new FormControl(model.mail, [Validators.required, Validators.email, Validators.pattern(RegexPatterns.Email)]),
      key: 'mail',
      label: 'mail',
      order: 3,
    }),
    new LipoFormCheckbox({
      value: new FormControl(model.createUsersInKeyCloak),
      key: 'createUsersInKeyCloak',
      label: 'createUsersInKeycloak',
      order: 4,
    }),
  ];

  fields.push(...handleAdditionalFields(model));

  return scheduled([fields], asyncScheduler);
}

function handleAdditionalFields(model: CustomerDetailModel): LipoFormControlModel[] {
  const result: LipoFormControlModel[] = []
  if (model.id) {
    return result;
  }

  const additionalFields = [
    new LipoFormCheckbox({
      value: new FormControl(model.sendMailToCustomer),
      key: 'sendMailToCustomer',
      label: 'sendMailToCustomer',
      order: 5,
    }),
    new LipoFormCheckbox({
      value: new FormControl(model.sendEmailToPartner),
      key: 'sendEmailToPartner',
      label: 'sendMailToPartner',
      order: 6,
    })
  ]
  result.push(...additionalFields)

  return result
}

export function getAdminCustomerDetailFormFields(partners: PartnerServiceModel[], model: CustomerDetailModel): Observable<LipoFormControlModel[]> {
  const fields: LipoFormControlModel[] = [
    new LipoFormSelect({
      value: new FormControl(partners.find(partner => partner.id === model.partner), Validators.required),
      key: 'partner',
      label: 'partner',
      settings: {
        options: partners.map(partner => ({key: partner.name, value: partner})),
      },
    })
  ]
  return scheduled([fields], asyncScheduler);
}
