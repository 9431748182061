import {TenantUserMappingDetailModel} from "../components/models/tenant-user-mapping-detail.model";
import {TenantUserMappingServiceModel} from "../services/models/tenant-user-mapping-service.model";
import {LipoDetailModel} from "../../shared/models/lipo-detail.model";
import {LipoFormModel} from "../../shared/models/lipo-form.model";
import {LipoTab} from "../../shared/components/models/lipoTab";
import {TenantAppSettingsDetailMapper} from "./tenant-app-settings-detail.mapper";
import {UserDetailMapper} from "../../user/mappers/user-detail.mapper";

export class TenantUserMappingDetailMapper {
  public static toTenantUserMappingServiceModel(tenantUserMappingDetailModel: TenantUserMappingDetailModel): TenantUserMappingServiceModel {
    return {
      sapUserName: tenantUserMappingDetailModel.sapUserName,
      sapUserCode: tenantUserMappingDetailModel.sapUserCode,
      user: UserDetailMapper.toUserServiceModel(tenantUserMappingDetailModel.user!!),
      tenantId: tenantUserMappingDetailModel.tenantId!!,
      appSettings: tenantUserMappingDetailModel.appSettings?.map(TenantAppSettingsDetailMapper.toTenantAppSettingsServiceModel) ?? null,
      id: tenantUserMappingDetailModel.id,
    }
  }

  public static toLipoDetailModel(tenantUserMappingDetailModel: TenantUserMappingDetailModel, formModel?: LipoFormModel, tabs?: LipoTab[]): LipoDetailModel {
    return new LipoDetailModel(
      tenantUserMappingDetailModel.id ? tenantUserMappingDetailModel.sapUserName : 'userMappings',
      tenantUserMappingDetailModel.sapUserCode ? `#${tenantUserMappingDetailModel.sapUserCode}` : null,
      null,
      formModel,
      tabs
    )
  }
}
