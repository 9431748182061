@if(_progressService.isLoading()) {
  <du-lipo-detail-skeleton></du-lipo-detail-skeleton>
} @else {
  <du-lipo-breadcrumbs-menu/>
  <div *ngIf="detailModel" class="container">
    <div class="container-header">
      <div class="container-header-content">
        <div class="dense-3">
          <button mat-icon-button matTooltip="{{'back' | translate}}" class="detail-header-button-back" (click)="handleNavigateBack()">
            <mat-icon fontIcon="chevron_left"></mat-icon>
          </button>
        </div>
        @if (detailModel.logoUrl && detailModel.logoUrl.length > 0) {
          <div class="detail-header-img-container">
            <img
              [ngSrc]="detailModel.logoUrl" class="card-header-img"
              alt="{{detailModel.title}} logo"
              width="64" height="64" priority>
          </div>
        }
        <div class="detail-header-title-container">
          <h1 class="mat-headline-small detail-title">
            {{ detailModel.title | translate }}
          </h1>
          <p class="mat-body-medium detail-subtitle">
            {{ getSubtitle() | translate }}
          </p>
        </div>

      </div>

      <div>
        <div class="container-header-action">
          <div class="container-action">
            @for (button of headerButtons; track button) {
              <button mat-stroked-button (click)="button.onClick()" [class]="button.className">
                <mat-icon [fontSet]="button.iconFontSet">{{ button.icon }}</mat-icon>
                {{ button.text | translate | uppercase }}
              </button>
            }
            @if(_isSaving) {
              <button mat-stroked-button [disabled]="true">
                <mat-progress-spinner [diameter]="24" mode="indeterminate"></mat-progress-spinner>
              </button>
            } @else {
              <button mat-stroked-button *ngIf="!hideSaveButton" (click)="onSubmitTriggered()" [disabled]="!isFormValid">
                {{ "button.save" | translate | uppercase }}
                <mat-icon fontIcon="save"></mat-icon>
              </button>
            }
            @if (menuButtons.length > 0) {
            <button mat-icon-button [matMenuTriggerFor]="headerActionMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #headerActionMenu="matMenu" class="headerActionMenu">
              @for (button of menuButtons; track $index) {
                <button mat-menu-item (click)="button.onClick()" [class]="button.className">
                  <mat-icon [fontSet]="button.iconFontSet">{{ button.icon }}</mat-icon>
                  <span>{{ button.text | translate | uppercase }}</span>
                </button>
              }
            </mat-menu>
            }
          </div>
        </div>
      </div>
    </div>
    <div>
      <mat-card>
        <mat-card-header class="detail-card-header">
        </mat-card-header>
        <mat-card-content>
          @if (detailModel.form && !isReadOnly) {
            <du-lipo-form [formModel]="detailModel.form" (onSubmit)="onSubmitTriggered()"></du-lipo-form>
          } @else if (isReadOnly){
            <du-lipo-readonly [formModel]="detailModel.form"></du-lipo-readonly>
          }
          @if (detailModel.tabs) {
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" [@.disabled]="true">
              @for (tab of detailModel.tabs; track tab) {
                <mat-tab [label]="tab.title | translate">
                  @for (component of tab.components; track component) {
                    @if (isTypeAny(component.component)) {
                      <ng-container *ngComponentOutlet="component.component; inputs: component.inputs;"/>
                    } @else if (isLipoTableModel(component.component)) {
                      <du-lipo-table [lipoTable]="component.component"></du-lipo-table>
                    }
                  }
                </mat-tab>
              }
            </mat-tab-group>

          }
        </mat-card-content>
      </mat-card>
    </div>
  </div>
}
