import {MobileLogisticsSettingsModel, TenantAppSettingsDetailModel} from "../models/tenant-app-settings-detail.model";
import {
  AppLicenseSettingsConfiguration,
  MobileLogisticSettingsConfiguration
} from "../../../shared/services/models/configuration.model";
import {asyncScheduler, lastValueFrom, Observable, pairwise, scheduled, startWith, toArray} from "rxjs";
import {
  LipoFormCheckbox,
  LipoFormColor,
  LipoFormControlModel,
  LipoFormSelect,
  LipoFormTextArea
} from "../../../shared/components/models/lipo-form-control.model";
import {FormControl, Validators} from "@angular/forms";
import {validColorValidator} from 'ngx-colors';
import {LipoFormGroupModel} from "../../../shared/components/models/lipo-form-group.model";
import {ProductServiceModel} from "../../../product/services/models/product-service.model";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {fromStrDict} from "../../../utils/str-dict";

export function getTenantAppSettingsDetailFormFields(
  model: TenantAppSettingsDetailModel,
  appSettingsConfig: AppLicenseSettingsConfiguration,
  products: ProductServiceModel[] = [],
  detailModel: LipoDetailModel,
) {
  let appNameHasValue = model.appName !== null && model.appName !== undefined;
  let appNameControl = new FormControl({value: model.appName, disabled: appNameHasValue}, [Validators.required])

  const appSettingsFields: LipoFormControlModel[] = [
    new LipoFormSelect({
      value: appNameControl,
      key: 'appName',
      label: 'appName',
      settings: {
        options: products.map(product => ({key: product.name, value: product.name})),
      },
    }),
    new LipoFormTextArea({
      value: new FormControl(model.description),
      key: 'description',
      label: 'description',
    }),
  ]

  detailModel.form?.addGroup(new LipoFormGroupModel({key: 'appSettings', label: 'appSettings', order: 1, controls: appSettingsFields}))

  if (appNameControl.value !== null) {
    addAppNameControl(appNameControl.value, appSettingsConfig, detailModel, model.attributes).then()
  } else {
    appNameControl.valueChanges?.pipe(
      startWith(null),
      pairwise()
    ).subscribe(([previousAppNameValue, currentAppNameValue]: [(string | null), (string | null)]) => {
      removeAppNameSettingsFromForm([previousAppNameValue], detailModel);
      addAppNameControl(currentAppNameValue, appSettingsConfig, detailModel, model.attributes).then();
    });
  }
}

/**
 * Adds and configures the application name control based on the provided parameters.
 *
 * @param {string | null} appNameValue - The value of the application name to control.
 * @param {AppLicenseSettingsConfiguration} appSettings - The application license settings configuration object.
 * @param {LipoDetailModel} detailModel - The detail model associated with the application.
 * @param {any | null} attributes - Additional attributes required for the control, or null if not provided.
 * @return {Promise<void>} A promise that resolves when the control operations are completed.
 */
export function addAppNameControl(appNameValue: string | null, appSettings: AppLicenseSettingsConfiguration, detailModel: LipoDetailModel, attributes: any | null): Promise<void> {
  if (appNameValue === appSettings.mobileLogistic.formKey) {
    const mobileLogisticsSettingsModel = createMobileLogisticsSettingsModel(attributes);
    return setMobileLogisticSettings(mobileLogisticsSettingsModel, appSettings.mobileLogistic, detailModel);
  }
  return Promise.resolve();
}

export function removeAppNameSettingsFromForm(appSettingsName: (string | null)[] = [], detailModel?: LipoDetailModel): void {
  if (detailModel === undefined) return;
  for (let appSettingName of appSettingsName) {
    if (appSettingName !== null) {
      let allGroupsOfType = Object.keys(detailModel.form?.formGroup?.controls || {}).filter(key => key.startsWith(appSettingName));
      allGroupsOfType.forEach(key => {
        detailModel.form?.removeGroup(key)
      })
    }
  }
}

function createMobileLogisticsSettingsModel(attributes: any | null): MobileLogisticsSettingsModel {
  if (attributes === null) {
    return new MobileLogisticsSettingsModel()
  }
  return fromStrDict<MobileLogisticsSettingsModel>(attributes);
}

async function setMobileLogisticSettings(
  mobilesModel: MobileLogisticsSettingsModel,
  settings: MobileLogisticSettingsConfiguration,
  detailModel: LipoDetailModel
): Promise<void> {
  const formGroups = await lastValueFrom(getMobileLogisticSettings(mobilesModel, settings).pipe(toArray()));

  if (detailModel) {
    formGroups.forEach(detailModel.form!.addGroup, detailModel.form);
  }
}

export function getMobileLogisticSettings(model: MobileLogisticsSettingsModel, settings: MobileLogisticSettingsConfiguration): Observable<LipoFormGroupModel> {
  const companyPoliciesFields: LipoFormControlModel[] = [
    new LipoFormCheckbox({
      value: new FormControl(model.CompanyPolicies.HideQuantities),
      key: 'HideQuantities',
      label: 'hideQuantities',
    }),
    new LipoFormCheckbox({
      value: new FormControl(model.CompanyPolicies.PackagingItemAllocation),
      key: 'PackagingItemAllocation',
      label: 'packagingItemAllocation',
    }),
    new LipoFormCheckbox({
      value: new FormControl(model.CompanyPolicies.ItemScanningCountingEnabled),
      key: 'ItemScanningCountingEnabled',
      label: 'itemScanningCountingEnabled',
    }),
    new LipoFormCheckbox({
      value: new FormControl(model.CompanyPolicies.ItemScanningPicklistEnabled),
      key: 'ItemScanningPicklistEnabled',
      label: 'itemScanningPicklistEnabled',
    }),
    new LipoFormCheckbox({
      value: new FormControl(model.CompanyPolicies.ItemScanningGoodsReceiptEnabled),
      key: 'ItemScanningGoodsReceiptEnabled',
      label: 'itemScanningGoodsReceiptEnabled',
    }),
  ]

  const mobileLogisticSettingsBaseFields: LipoFormControlModel[] = [
    new LipoFormColor({
      value: new FormControl(model.NavBarColor, [Validators.required, validColorValidator()]),
      key: 'NavBarColor',
      label: 'navBarColor',
    }),
    new LipoFormColor({
      value: new FormControl(model.NavBarTextAccentColor, [Validators.required, validColorValidator()]),
      key: 'NavBarTextAccentColor',
      label: 'navBarTextAccentColor',
    }),
    new LipoFormColor({
      value: new FormControl(model.NavBarTextColor, [Validators.required, validColorValidator()]),
      key: 'NavBarTextColor',
      label: 'navBarTextColor',
    }),
    new LipoFormSelect({
      value: new FormControl(model.Subscriptions.split('|'), [Validators.required]),
      key: 'Subscriptions',
      label: 'subscription.type',
      settings: {
        options: settings.subscriptions.map(it =>  ({key: it.translation, value: it.name})),
        multiSelect: true
      }
    })
  ]

  return scheduled([
      new LipoFormGroupModel({key: `${settings.formKey}_base`, label: settings.formKey, order: 2, controls: mobileLogisticSettingsBaseFields}),
      new LipoFormGroupModel({key: `${settings.formKey}_companyPolicy`, label: 'companyPolicy', order: 3, controls: companyPoliciesFields}),
  ], asyncScheduler);
}
