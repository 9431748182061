import {TenantUserMappingDetailModel} from "../models/tenant-user-mapping-detail.model";
import {LipoTableDisplayedColumns, LipoTableModel} from "../../../shared/components/models/lipo-table.model";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {MatTableDataSource} from "@angular/material/table";
import {TenantTokenDetailModel} from "../models/tenant-token-detail.model";
import {DatePipe} from "@angular/common";
import {TenantAppSettingsDetailModel} from "../models/tenant-app-settings-detail.model";
import {AppLicenseDetailModel} from "../../../licence/components/models/app-license-detail.model";
import {PipeTransform} from "@angular/core";

export function getTenantUserMappingsTable(userMapping: TenantUserMappingDetailModel[], buttons?: LipoButton[], onRowClick?: (value: LipoModelInterface) => Promise<void>): LipoTableModel<TenantUserMappingDetailModel> {
  let tableDataSource = new MatTableDataSource<TenantUserMappingDetailModel>(userMapping)
  let displayedColumns = LipoTableDisplayedColumns.build(
    {HeaderCellName: 'firstName', PropertyName: 'firstName'},
    {HeaderCellName: 'lastName', PropertyName: 'lastName'},
    {HeaderCellName: 'mail', PropertyName: 'mail'},
    {HeaderCellName: 'sapUserName', PropertyName: 'sapUserName'},
    {HeaderCellName: 'sapUserCode', PropertyName: 'sapUserCode'},
    {HeaderCellName: 'userId', PropertyName: 'userId'},
  );

  return new LipoTableModel<TenantUserMappingDetailModel>(
    tableDataSource,
    displayedColumns,
    undefined,
    buttons,
    onRowClick
  );
}

export function getTenantTokenTable(tenantToken: TenantTokenDetailModel[], datePipe: DatePipe, buttons?: LipoButton[], onRowClick?: (value: LipoModelInterface) => Promise<void>): LipoTableModel<TenantTokenDetailModel> {
  let tableDataSource = new MatTableDataSource<TenantTokenDetailModel>(tenantToken)
  let displayedColumns = LipoTableDisplayedColumns.build(
    {HeaderCellName: 'name', PropertyName: 'name'},
    {HeaderCellName: 'apiToken', PropertyName: 'token'},
    {HeaderCellName: 'accessRights', PropertyName: 'accessRights'},
    {HeaderCellName: 'valid.to', PropertyName: 'validUntil', BodyPipes: [datePipe]},
  );

  return new LipoTableModel<TenantTokenDetailModel>(
    tableDataSource,
    displayedColumns,
    undefined,
    buttons,
    onRowClick
  );
}

export function getAppSettingsTable(tenantAppSettings: TenantAppSettingsDetailModel[], buttons?: LipoButton[], onRowClick?: (value: LipoModelInterface) => Promise<void>): LipoTableModel<TenantAppSettingsDetailModel> {
  let tableDataSource = new MatTableDataSource<TenantAppSettingsDetailModel>(tenantAppSettings)
  let displayedColumns = LipoTableDisplayedColumns.build(
    {HeaderCellName: 'appName', PropertyName: 'appName'},
    {HeaderCellName: 'description', PropertyName: 'description'},
  );

  return new LipoTableModel<TenantAppSettingsDetailModel>(
    tableDataSource,
    displayedColumns,
    undefined,
    buttons,
    onRowClick
  );
}

export function getAppLicenseTable(appLicenses: AppLicenseDetailModel[], datePipe: PipeTransform, buttons?: LipoButton[], onRowClick?: (value: LipoModelInterface) => Promise<void>): LipoTableModel<AppLicenseDetailModel> {
  let tableDataSource = new MatTableDataSource<AppLicenseDetailModel>(appLicenses)
  let displayedColumns = LipoTableDisplayedColumns.build(
    {HeaderCellName: 'purchaseDate', PropertyName: 'purchaseDate', BodyPipes: [datePipe]},
    {HeaderCellName: 'expiryDate', PropertyName: 'expiryDate', BodyPipes: [datePipe]},
    {HeaderCellName: 'activate', PropertyName: 'isActiveText'},
  );

  return new LipoTableModel<AppLicenseDetailModel>(
    tableDataSource,
    displayedColumns,
    undefined,
    buttons,
    onRowClick
  );
}
