import {asyncScheduler, Observable, scheduled} from "rxjs";
import {
  LipoFormAutocomplete,
  LipoFormControlModel,
  LipoFormNumeric,
  LipoFormSelect,
  LipoFormTextbox
} from "../../../shared/components/models/lipo-form-control.model";
import {FormControl, Validators} from "@angular/forms";
import {TenantUserMappingDetailModel} from "../models/tenant-user-mapping-detail.model";
import {UserServiceModel} from "../../../user/services/models/user-service.model";
import {TenantServiceModel} from "../../services/models/tenant-service.model";
import {LipoFormGroupModel} from "../../../shared/components/models/lipo-form-group.model";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {TranslateService} from "@ngx-translate/core";

export function getTenantUserMappingDetailFormFields(model: TenantUserMappingDetailModel, users: UserServiceModel[], tenants: TenantServiceModel[], snackbar: SnackbarService, translate: TranslateService): Observable<LipoFormGroupModel> {
  const userControl = new FormControl(users.find(user => user.id === model.user?.id), Validators.required)
  const fields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.sapUserName, [Validators.required]),
      key: 'sapUserName',
      label: 'sapUserName',
    }),
    new LipoFormNumeric({
      value: new FormControl(model.sapUserCode, [Validators.required, Validators.min(0)]),
      key: 'sapUserCode',
      label: 'sapUserCode',
    }),
    new LipoFormSelect({
      value: new FormControl({value: tenants.find(tenant => tenant.id === model.tenantId)?.id, disabled: model.tenantId !== null}, Validators.required),
      key: 'tenantId',
      label: 'tenant',
      settings: {
        options: tenants.map(tenant => ({key: tenant.sapCompanyDB, value: tenant.id})),
      },
    }),
    new LipoFormAutocomplete({
      value: userControl,
      key: 'user',
      label: 'user',
      settings: {
        options: users.map(user => ({key: `${user.lastName} ${user.firstName} - ${user.email}`, value: user})),
      },
    }),
  ]

  if (userControl.enabled) {
    userControl.valueChanges?.subscribe({
      next: appNameValue => {
        if (appNameValue?.keyCloakUserId === null)
        {
          snackbar.Warning(translate.instant('snackbar.userNoKeycloakMapping'));
        }
      }
    });
  }

  return scheduled([
    new LipoFormGroupModel({key: `tenantUserMapping`, label: undefined, order: 1, controls: fields}),
  ], asyncScheduler);
}
