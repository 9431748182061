import {Component, OnInit} from '@angular/core';
import {LipoOverviewComponent} from "../../../shared/components/lipo-overview/lipo-overview.component";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {LipoOverviewModel} from "../../../shared/components/models/lipo-overview.model";
import {Router} from "@angular/router";
import {SystemDataService} from "../../services/system-data.service";
import {SystemServiceMapper} from "../../mappers/system-service.mapper";
import {SystemOverviewMapper} from "../../mappers/system-overview.mapper";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {ProgressService} from "../../../shared/services/progress.service";

@Component({
  selector: 'du-system-overview',
  standalone: true,
    imports: [
        LipoOverviewComponent
    ],
  templateUrl: './system-overview.component.html',
  styleUrl: './system-overview.component.scss'
})
export class SystemOverviewComponent implements OnInit{
  systemOverviewModel?: LipoOverviewModel;

  protected readonly LipoRouteEnum = LipoRouteEnum;

  constructor(
    private systemService: SystemDataService,
    protected router: Router,
    private _progressService: ProgressService,
  ) {
    _progressService.startLoading()
  }

  ngOnInit(): void {
    this.systemService.getSystemsSmall().subscribe({
      next: system => {
        let systemsOverview = system.map(SystemServiceMapper.toSystemOverviewModelSmall);
        let systemCards = systemsOverview.map(SystemOverviewMapper.toLipoCard);

        this.systemOverviewModel = {
          title: 'systems',
          buttons: LipoButton.build({
            text: "button.system.add",
            icon: "add",
            onClick: async () => await this.router.navigate([LipoRouteEnum.SYSTEM, 'new'])
          }),
          cards: systemCards
        }
      },
      complete: () => this._progressService.stopLoading()
    });
  }
}
