import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {SystemOverviewServiceModel, SystemSaveServiceModel, SystemServiceModel} from "./models/system-service.model";
import {ISystemDataInterface} from "./system-data.interface";
import {HttpClient} from "@angular/common/http";
import {ConfigurationService} from "../../shared/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class SystemDataService implements ISystemDataInterface {

  private readonly endpoint: string;
  private readonly api: string;

  constructor(
    private http: HttpClient,
    private config: ConfigurationService
  ) {
    this.endpoint = this.config.configuration.api.endpoints.system;
    this.api = this.config.configuration.api.backendUrl;
  }

  createSystem(system: SystemSaveServiceModel): Observable<SystemServiceModel> {
    return this.http.post<SystemServiceModel>(`${this.api}/${this.endpoint}`, system);
  }

  deleteSystem(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.api}/${this.endpoint}/${id}`);
  }

  getSystem(id: number): Observable<SystemServiceModel> {
    return this.http.get<SystemServiceModel>(`${this.api}/${this.endpoint}/${id}`);
  }

  getSystems(): Observable<SystemServiceModel[]> {
    return this.http.get<SystemServiceModel[]>(`${this.api}/${this.endpoint}/full`);
  }

  getSystemsSmall(): Observable<SystemOverviewServiceModel[]> {
    return this.http.get<SystemOverviewServiceModel[]>(`${this.api}/${this.endpoint}`);
  }

  getSystemsByCustomer(id: number): Observable<SystemServiceModel[]> {
    return this.http.get<SystemServiceModel[]>(`${this.api}/${this.endpoint}/${id}`);
  }

  updateSystem(system: SystemServiceModel): Observable<SystemServiceModel> {
    return this.http.put<SystemServiceModel>(`${this.api}/${this.endpoint}/${system.id}`, system);
  }
}
