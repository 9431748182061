import {SystemDetailModel, SystemSaveDetailModel} from "../models/system-detail.model";
import {asyncScheduler, Observable, scheduled} from "rxjs";
import {
  LipoFormCheckbox,
  LipoFormControlModel,
  LipoFormPassword,
  LipoFormSelect,
  LipoFormTextbox
} from "../../../shared/components/models/lipo-form-control.model";
import {FormControl, Validators} from "@angular/forms";
import {RegexPatterns} from "../../../utils/regex-patterns";
import {CustomerServiceModel} from "../../../customer/services/models/customer-service.model";
import {fixUmlauts} from "../../../utils/umlaut";
import {LipoFormGroupModel} from "../../../shared/components/models/lipo-form-group.model";

export function getSystemDetailFormFields(model: SystemDetailModel, isAdmin: boolean, customers?: CustomerServiceModel[]): Observable<LipoFormGroupModel[]> {
  const checkBoxes: LipoFormControlModel[] = [
    new LipoFormCheckbox({
      value: new FormControl(model.isActive),
      key: 'isActive',
      label: 'active',
    }),
  ]

  const generalFields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.name, [Validators.required, Validators.pattern(RegexPatterns.Name)]),
      key: 'name',
      label: 'name',
    }),
    new LipoFormSelect({
      value: new FormControl(customers?.find(customer => customer.id === model.customer)?.id, Validators.required),
      key: 'customer',
      label: 'customer',
      settings: {
        options: customers?.map(customer => ({key: customer.name, value: customer.id})),
      },
    }),
    new LipoFormTextbox({
      value: new FormControl(model.sapSystemId, Validators.required),
      key: 'sapSystemId',
      label: 'sapSystemId',
      tooltip: 'tooltip.sapSystemId',
    }),
    new LipoFormTextbox({
      value: new FormControl(model.identity, Validators.required),
      key: 'identity',
      label: 'identity',
      tooltip: 'tooltip.identity',
    }),

  ];

  const technicalFields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.hardwareKey),
      key: 'hardwareKey',
      label: 'hardwareKey',
      tooltip: 'tooltip.hardwareKey',
    }),
    new LipoFormTextbox({
      value: new FormControl({value: model.initialDate, disabled: true}),
      key: 'initialDate',
      label: 'initialDate',
    }),
    new LipoFormTextbox({
      value: new FormControl(model.logoUrl, Validators.required),
      key: 'logoUrl',
      label: 'logoUrl',
    }),
    new LipoFormTextbox({
      value: new FormControl(model.basePath, Validators.required),
      key: 'basePath',
      label: 'basePath',
    }),
  ]

  const dbFields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.dbUserName, Validators.required),
      key: 'dbUserName',
      label: 'dbUserName',
    }),
    new LipoFormPassword({
      value: new FormControl(model.dbPassword, [Validators.required]),
      key: 'dbPassword',
      label: 'dbPassword',
    }),
  ];

  const sapFields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.sapServerName, Validators.required),
      key: 'sapServerName',
      label: 'sapServerName',
    }),
    new LipoFormTextbox({
      value: new FormControl(model.sapServerType, Validators.required),
      key: 'sapServerType',
      label: 'sapServerType',
    }),
    new LipoFormTextbox({
      value: new FormControl(model.sapLicServerName),
      key: 'sapLicServerName',
      label: 'sapLicServerName',
    }),
    new LipoFormTextbox({
      value: new FormControl(model.sapServiceLayerName),
      key: 'sapServiceLayerName',
      label: 'sapServiceLayerName',
    }),
  ]

  let formGroupModels: LipoFormGroupModel[] = [
    new LipoFormGroupModel({key: 'general', label: 'general', order: 1, controls: generalFields}),
    new LipoFormGroupModel({key: 'db', label: 'db', order: 4, controls: dbFields}),
    new LipoFormGroupModel({key: 'checkBoxes', order: 6, controls: checkBoxes})
  ];

  if (isAdmin) {
    formGroupModels.push(
      new LipoFormGroupModel({key: 'sap', label: 'sap', order: 2, controls: sapFields}),
      new LipoFormGroupModel({key: 'technical', label: 'technical', order: 5, controls: technicalFields}));
  }

  return scheduled([formGroupModels], asyncScheduler);
}

export function getSystemSaveDetailFormFields(model: SystemSaveDetailModel, customers?: CustomerServiceModel[]): Observable<LipoFormControlModel[]> {
  let systemName = new FormControl({
    value: model.name,
    disabled: false
  }, [Validators.required, Validators.pattern(RegexPatterns.SystemName)])
  let identityName = new FormControl({value: model.identity, disabled: true}, Validators.required)

  const fields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: systemName,
      key: 'name',
      label: 'name',
    }),
    new LipoFormSelect({
      value: new FormControl(model.customer, Validators.required),
      key: 'customer',
      label: 'customer',
      settings: {
        options: customers?.map(customer => ({key: customer.name, value: customer.id})),
      },
    }),
    new LipoFormTextbox({
      value: identityName,
      key: 'identity',
      label: 'identity',
      tooltip: 'tooltip.identity',
    }),
    new LipoFormTextbox({
      value: new FormControl(model.hardwareKey),
      key: 'hardwareKey',
      label: 'hardwareKey',
      tooltip: 'tooltip.hardwareKey',
    }),
  ];

  systemName.valueChanges.subscribe({
    next: value => {
      if (value != null) {
        identityName.setValue(cleanSystemName(value))
      }
    }
  })

  return scheduled([fields], asyncScheduler);
}

function cleanSystemName(systemName: string): string {
  return fixUmlauts(systemName
    .toLowerCase()
    .trim());
}
