import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LipoFormControlTypeEnum} from "../../enums/lipo-form-control-type.enum";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {LipoFormModel} from "../../models/lipo-form.model";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRadioModule} from "@angular/material/radio";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {LipoFormGroupModel} from "../models/lipo-form-group.model";
import {LipoFormControlModel} from "../models/lipo-form-control.model";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatTooltip} from "@angular/material/tooltip";
import {MatIcon} from "@angular/material/icon";
import {NgxColorsModule} from "ngx-colors";
import {MatButtonModule, MatIconButton} from "@angular/material/button";
import {TokenFormatInputDirective} from "../directive/format-input.directive";
import {NgIf, UpperCasePipe} from "@angular/common";
import {MatAutocomplete, MatAutocompleteTrigger} from "@angular/material/autocomplete";

@Component({
  selector: 'du-lipo-form',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    TranslateModule,
    MatDatepickerModule,
    MatTooltip,
    MatIcon,
    NgxColorsModule,
    FormsModule,
    MatIconButton,
    TokenFormatInputDirective,
    NgIf,
    UpperCasePipe,
    MatButtonModule,
    MatAutocompleteTrigger,
    MatAutocomplete,
  ],
  templateUrl: './lipo-form.component.html',
  styleUrl: './lipo-form.component.scss'
})
export class LipoFormComponent{
  @Input() formModel?: LipoFormModel;
  @Output() onSubmit = new EventEmitter();

  protected readonly LipoFormControlTypeEnum = LipoFormControlTypeEnum;
  protected readonly colorFormat: string = "hex";

  constructor(
    private _translateService: TranslateService,
  ) {
  }
  hasError(controlModel: LipoFormControlModel): boolean {
    let control = controlModel.value
    return control.invalid && control.touched;
  }

  getError(controlModel: LipoFormControlModel): string {

    const control = controlModel.value;
    const controlKey = controlModel.key;

    const hasPatternError = control?.hasError('pattern');
    if (control?.hasError('required')) {
      return 'error.required';
    } else if (hasPatternError) {
      switch (controlModel.controlType) {
        case LipoFormControlTypeEnum.MAIL:
          return 'error.EmailInvalid';
        case LipoFormControlTypeEnum.PHONE:
          return 'error.PhoneInvalid';
        case LipoFormControlTypeEnum.PASSWORD:
          return 'error.PasswordInvalid';
        case LipoFormControlTypeEnum.TEXT:
          if (controlKey.includes('name')) {
            return 'error.NameInvalid';
          }
      }
    }

    switch (controlModel.controlType) {
      case LipoFormControlTypeEnum.DATE:
        if (control?.hasError('minDate')) {
          let error = control?.getError('minDate')
          return this._translateService.instant(error?.message, {
            minDate: error?.minDate
          });
        }
        break;
      case LipoFormControlTypeEnum.NUMERIC:
        if (controlKey.includes('tokenLength')) {
          return 'error.tokenLengthTooShort';
        }
    }
    return '';
  }

  setColorInput(event: string, controlModel: LipoFormControlModel) {
    controlModel.value.setValue(event)
  }

  setColorPicker(event: Event, controlModel: LipoFormControlModel) {
    const inputElement = event.target as HTMLInputElement;
    controlModel.value.setValue(inputElement.value)
  }

  clickPasswordIconEvent(event: MouseEvent, controlModel: LipoFormControlModel): void {
    if (this.shouldDisablePasswordField(controlModel)) return;
    controlModel.settings['visible'] = !controlModel.settings['visible'];
    event.stopPropagation();
  }

  shouldDisablePasswordField(controlModel: LipoFormControlModel): boolean {
    return controlModel.settings['readonly'] || false;
  }

  protected readonly LipoFormGroupModel = LipoFormGroupModel;
}
